import React, { FC } from "react";

import { EmailTemplate } from "interfaces";

import NurtureActiveSettingsRow from "components/Nurture/NurtureEditor/NurtureSequenceTable/NurtureActiveSettingsRow/NurtureActiveSettingsRow";
import NurtureInactiveReferrerSettingsRow from "components/Nurture/NurtureEditor/NurtureSequenceTable/NurtureInactiveReferrerSettingsRow/NurtureInactiveReferrerSettingsRow";
import NurtureMaxDaysSinceEventSettingsRow from "components/Nurture/NurtureEditor/NurtureSequenceTable/NurtureMaxDaysSinceEventSettingsRow/NurtureMaxDaysSinceEventSettingsRow";
import NurtureMaxDaysSinceSignupSettingsRow from "components/Nurture/NurtureEditor/NurtureSequenceTable/NurtureMaxDaysSinceSignupSettingsRow/NurtureMaxDaysSinceSignupSettingsRow";
import NurtureRepeatSettingsRow from "components/Nurture/NurtureEditor/NurtureSequenceTable/NurtureRepeatSettingsRow/NurtureRepeatSettingsRow";
import NurtureSequenceRow from "components/Nurture/NurtureEditor/NurtureSequenceTable/NurtureSequenceRow/NurtureSequenceRow";
import { NurtureConfig } from "components/Nurture/interfaces";

type NurtureSequenceTableProps = {
  nurtureType: string | null;
  nurtureConfig: NurtureConfig;
  emailTemplates: EmailTemplate[];
  handleEmailTemplateChange: (
    e: React.ChangeEvent<HTMLSelectElement> | Event
  ) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputToggle: (id: string, value: boolean) => void;
  handleRemoveSequenceStep: (index: number) => void;
  handleActiveToggle: (id: string, value: boolean) => void;
};

const NurtureSequenceTable: FC<NurtureSequenceTableProps> = ({
  nurtureType,
  nurtureConfig,
  emailTemplates,
  handleEmailTemplateChange,
  handleInputChange,
  handleInputToggle,
  handleRemoveSequenceStep,
  handleActiveToggle,
}) => {
  return (
    <>
      <table className="w-full table-auto">
        <thead className="bg-blue-50 text-sm text-left">
          <tr>
            <th className="w-1/12 px-4 py-2 font-normal">#</th>
            <th className="w-7/12 px-4 py-2 font-normal">Email</th>
            <th className="w-3/12 px-4 py-2 font-normal">Interval</th>
            <th className="w-1/12 px-4 py-2 font-normal"></th>
          </tr>
        </thead>
        <tbody>
          {nurtureConfig.sequences.map(
            (step, index) =>
              !step._destroy && (
                <NurtureSequenceRow
                  key={index}
                  nurtureSequenceStep={step}
                  nurtureSequenceStepIndex={index}
                  emailTemplates={emailTemplates}
                  handleEmailTemplateChange={handleEmailTemplateChange}
                  handleInputChange={handleInputChange}
                  handleRemoveSequenceStep={handleRemoveSequenceStep}
                />
              )
          )}
          <NurtureRepeatSettingsRow
            nurtureConfig={nurtureConfig}
            handleInputToggle={handleInputToggle}
            handleInputChange={handleInputChange}
          />
          {nurtureType !== "referrer_inactive_nurture" && (
            <>
              <NurtureMaxDaysSinceEventSettingsRow
                nurtureConfig={nurtureConfig}
                handleInputToggle={handleInputToggle}
                handleInputChange={handleInputChange}
              />
            </>
          )}
          {nurtureType === "referrer_inactive_nurture" && (
            <>
              <NurtureMaxDaysSinceSignupSettingsRow
                nurtureConfig={nurtureConfig}
                handleInputToggle={handleInputToggle}
                handleInputChange={handleInputChange}
              />
              <NurtureInactiveReferrerSettingsRow
                nurtureConfig={nurtureConfig}
                handleInputChange={handleInputChange}
              />
            </>
          )}
          <NurtureActiveSettingsRow
            nurtureConfig={nurtureConfig}
            handleActiveToggle={handleActiveToggle}
          />
        </tbody>
      </table>
    </>
  );
};

export default NurtureSequenceTable;
